<template>
  <div>
    <div v-if="data" class="row justify-center q-ma-md">
      <q-card
        v-for="product in data.items"
        :key="product.item"
        class="col-xs-12 col-sm-3 q-ma-sm"
      >
        <q-card-section class="q-pa-none">
          <div class="text-h6 text-center">Item {{ product.item }}</div>
        </q-card-section>
        <q-separator size="1px" color="black" />
        <q-card-section>
          <div class="col-12">
            <span class="text-bold">{{
              `${product.productCode} - ${product.productDescription}`
            }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Unidade: </span>
            <span>{{ product.um }}</span>
            <span class="text-bold"> | Quantidade: </span>
            <span>{{ formatString(product.quantity, "number") }}</span>
            <span class="text-bold"> | IPI: </span>
            <span>{{
              formatString(product.ipi, "currency", null, data.currency)
            }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Entrega: </span>
            <span>{{ formatString(product.deliveryDate, "date") }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">CC: </span>
            <span>{{ data.costCenter }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">SC: </span>
            <span>{{ product.purchaseRequest }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Valor Últ. Entrada: </span>
            <span>{{
              formatString(
                product.valueLastPurchase,
                "currency",
                null,
                data.currency
              )
            }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Qtd Últ. Entrada: </span>
            <span>{{
              formatString(product.quantityLastPurchase, "number")
            }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Consumo Médio Mês: </span>
            <span>{{ formatString(product.averageStock, "number") }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Estoque Atual: </span>
            <span>{{
              formatString(
                product.currentStock ? product.currentStock : 0,
                "number"
              )
            }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Dias de Estoque: </span>
            <span>{{
              formatString(product.daysLeft ? product.daysLeft : 0, "number")
            }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Obs.: </span>
            <span>{{ product.note }}</span>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import formatString from "../../helpers/formatString";
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    currency: {
      type: String,
      required: false,
      default: "BRL"
    }
  },
  methods: {
    formatString
  }
};
</script>

<style></style>
